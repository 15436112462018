export const index = {};

export const grudgeMixin = {
  methods: {
    grudeClass(color) {
      switch (color) {
        case 'ffffff':
          return 'grudge-white';
        case 'yellow':
          return 'grudge-gold';
        case 'f1efdd':
        case 'green':
          return 'grudge-dark';
        case 'darkgreen':
          return 'grudge-darker';
        case 'lightgreen':
          return 'grudge-green';
        default:
          return 'grudge-white';
      }
    },
  },
};

export const textColorMixin = {
  methods: {
    headerTextColor(value) {
      const colors = {
        darkblue: '#1d303e',
        lightblue: '#CAE0C7',
        darkgreen: '#3D6136',
        white: '#fff',
      };

      return value ? `color: ${colors[value]}` : '';
    },
  },
};
