<template lang="pug">
.home-header(
  :class="classObject"
  :style="styleObject"
)
  .inner
    .left
      h1(v-if="block.h1") {{ block.h1 }}
      h2(v-if="block.h2") {{ block.h2 }}
      .content(v-if="block.content" v-html="block.content")
      .button-group
        nuxt-link.button.gold(v-if="block.action_link" :to="block.action_link" :title="block.action_title") {{ block.action_text }}
        button.button.video(v-if="block.action2_link || block.video_link" :title="block.action2_title" @click="videoPopup = !videoPopup") {{ block.action2_text }}
    .right
      .frame(
        :class="`tilt-${block.img_tilt}`"
        v-if="isBoxed"
      )
        .mask(:style="{ backgroundImage: `url('${block.img}')` }")
  modal(v-model="videoPopup" :primary-button="false" :secondary-button="false" :padding="false" size="medium")
    iframe.embed(slot="body" :src="block.video_link")
    template(v-slot:title) {{ block.video_title }}
</template>

<script>
import { grudgeMixin } from '@/mixins/colorMixin';

export default {
  name: 'HomeHeader',
  components: {
    modal: () => import('@/components/modal'),
  },
  mixins: [grudgeMixin],
  props: {
    block: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: 'f1efdd',
    },
  },
  data() {
    return {
      videoPopup: false,
    };
  },
  computed: {
    styleObject() {
      return {
        ...(this.isBoxed ? {} : {
          backgroundImage: `url("${this.block.img}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }),
      };
    },
    classObject() {
      return [
        {
          boxed: this.isBoxed,
          'grudge-white': !this.isBoxed,
        },
        this.grudeClass(this.color),
      ];
    },
    isBoxed() {
      return this.block.boxed;
    },
  },
};
</script>

<style lang="scss">
.home-header {
  @include grudge-backgrounds();
  width: 100%;
  position: relative;
  min-height: 80vh;

  &.boxed {
    margin-bottom: 150px;
    min-height: 450px;

    &::before {
      content: '';
      position: absolute;
      bottom: -50px;
      width: 100%;
      height: 50px;
      background-position: bottom;
      background-size: cover;
    }
  }

  &.grudge-white {
    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      width: 100%;
      height: 50px;
      background-position: top;
      background-size: cover;
    }

    h1, h2, .content {
      color: $hunterGreen;
    }
  }

  &.grudge-dark h1 {
    color: $teaGreen;
  }

  &.grudge-gold {

    h1 {
      color: $white;
    }

    .button {

      &.gold {
        color: $white;
        background: $hunterGreen;
      }

      &.video {
        color: $hunterGreen;

        &::before {
          border: 1px solid $hunterGreen;
          color: $hunterGreen;
        }
      }
    }
  }

  .inner {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 80px 20px 60px 20px;
    box-sizing: border-box;
  }

  .left, .right {
    position: relative;
    width: 100%;
    height: 100%;
  }

  h1, h2 {
    margin: 0 0 20px 0;
    font-weight: 400;
    font-family: $fontBodoni;
  }

  h1 {
    color: $teaGreen;
    font-size: 60px;
    line-height: 60px;
  }

  h2 {
    color: $floralWhite;
    font-size: 30px;
    line-height: 40px;
  }

  .content {
    margin: 0 0 20px 0;
    color: $white;
    font-size: 20px;
    line-height: 30px;
  }

  .button {
    margin: 10px 10px 10px 0;

    &-group {
      display: flex;
      align-items: center;
    }
  }

  .video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 12px 0;
    color: $yellowCrayola;
    background: none;

    &::before {
      content: '\f04b';
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      margin-right: 20px;
      border: 1px solid $yellowCrayola;
      border-radius: 50%;
      font-weight: 700;
      font-size: 12px;
      font-family: $fontAwesome;
    }

    &:hover {
      color: $goldCrayola;
    }
  }

  .embed {
    width: 900px;
    max-width: 100%;
    height: 424px;
    max-height: calc(100vw / (16 / 9));
  }

  .frame {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding-bottom: 100%;
    background-image: url('~assets/images/photo-frame.png');
    background-position: center right;
    background-size: contain;
    background-repeat: no-repeat;

    &.tilt-right {
      transform: rotate(4deg);
    }

    &.tilt-left {
      transform: rotate(-4deg);
    }

    .mask {
      position: absolute;
      top: 5.5%;
      right: 12.5%;
      bottom: 27.5%;
      left: 21.5%;
      display: flex;
      align-items: center;
      background-position: center;
      background-size: cover;
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    min-height: 730px;

    &.boxed {
      margin-bottom: 60%;
    }

    .inner {
      flex-direction: column;
      padding: 40px 20px;
    }

    .button {
      margin: 10px 0;
    }

    .right {
      max-width: 450px;
      margin: 30px 0 0 0;
    }

    h1 {
      font-size: 44px;
      line-height: 44px;
    }

    h2 {
      font-size: 24px;
      line-height: 34px;
    }

    .content {
      font-size: 18px;
      line-height: 28px;
    }

    .frame {
      background-position: center;
      margin-top: -20px;

      .mask {
        right: 21%;
        left: 13.5%;
      }
    }
  }
  @media screen and (max-width: $breakpoint-sm) {

    &.boxed {
      margin-bottom: 65%;
    }

    .button {
      width: 100%;

      &-group {
        flex-direction: column;
      }
    }
  }
}
</style>
